<template>
  <footer>
    <div class="container">
      <div class="logo-fo">
        <img
        v-if="leng=='es'"
          src="@/assets/logo.svg"
        />
        <img v-else
          src="@/assets/logo-en.svg"
        />
      </div>
      <div class="call-to-actions">
        <div class="redes">
          <a
            target="_blank"
            data-toggle="tooltip"
            data-placement="top"
            title="Tooltip on top"
            href="mailto:contact@guillen-law.com"
            ><i class="far fa-envelope"></i
          ></a>
          <a target="_blank" href="https://www.facebook.com/GuillenLaw"><i class="fab fa-facebook"></i></a>
          <a target="_blank" href="https://www.linkedin.com/company/guillen-guillen-abogados/"><i class="fab fa-linkedin-in"></i></a>
          <a target="_blank" href="https://www.instagram.com/guillen_law/"><i class="fab fa-instagram"></i></a>
          <a
            target="_blank"
            href="https://api.whatsapp.com/send?phone=50431721915
"
            ><i class="fab fa-whatsapp"></i
          ></a>
        </div>

        <router-link class="lnk" to="/contact" >
          <button class="btn btn-primary">
            {{ leng == "es" ? "Contáctanos" : "Contact" }}
          </button></router-link
        >
      </div>
    </div>
    <div class="disclaimer">
      <p >
        {{
          leng == "es"
            ? "Copyright © 2021 Guillen & Guillen - Todos los derechos reservados"
            : "Copyright © 2021 Guillen & Guillen - All rights reserved"
        }}
      </p>
    </div>
  </footer>
</template>
<script>
import store from "@/store/index.js";
export default {
  name: "Footer",
  props: {
    msg: String,
  },
  data() {
    this.$i18n.locale = store.state.activo;
    return {
      leng: store.state.activo,
    };
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_responsive.scss";
footer {
  @include responsive(mobile) {
    margin-top: 0px;
  }
  width: 100%;
  height: 250px;
  background-color: #181648;

  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @include responsive(mobile) {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .logo-fo {
      img {
        width: 300px;
        @include responsive(mobile) {
          width: 200px;
        }
      }
    }
    .call-to-actions {
      width: 200px;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      @include responsive(mobile) {
        justify-content: center;
        align-items: center;
      }
      .btn {
        width: 150px;
        outline: none;
        &:focus {
          border: none;
        }
      }
      .redes {
        display: flex;
        font-size: 25px;
        @include responsive(mobile) {
          display: none;
        }
        a {
          transition: 0.3s ease-in-out;
          margin-left: 10px;
          color: white;
          &:hover {
            color: #c7893e;
          }
        }
      }
    }
  }
  .disclaimer {
    height: 40px;
    width: 100%;
    background-color: #c7893e;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    @include responsive(mobile) {
      p {
        font-size: 10px;
      }
    }
    p {
      margin-top: 15px;
    }
  }
}
</style>
