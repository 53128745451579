
<i18n>
{
  "en": {"Titulo-Home": "We are the integral solution for all your legal needs in Honduras",
  "sub-home":"More than 25 years of experience",
   "contact":"Contact us"

 
  },

  "es": {"Titulo-Home": "Somos la solución integral para todas tus necesidades legales en Honduras",
    "sub-home":"Más de 25 años de experiencia",
    "contact":"¡Contáctanos!"

  }
}
</i18n>
<template>
  <section class="home">
    <div class="desgradado"></div>
    <div class="texto container">
      <div class="text">
        <!-- <transition
        @before-enter="beforeEnter"
        @enter="enter"
        appear
        > -->
        <h2
          class="titulo wow animate__fadeIn"
          data-wow-delay="0.5s"
          data-wow-duration="1s"
        >
          {{ $t("Titulo-Home") }}
        </h2>
        <!-- </transition> -->
        <div class="linea wow animate__fadeIn" data-wow-delay="0.5s"></div>
        <h5
          class="wow animate__fadeIn"
          style="text-transform: none !important"
          data-wow-delay="0.5s"
        >
          {{ $t("sub-home") }}
        </h5>
        <a href="/contact">
        <button
          type="button"
          class="btn btn-primary wow animate__fadeIn"
          data-wow-delay="0.5s"
        >{{ $t("contact") }}
          
        </button>
        </a>
      </div>
    </div>
  </section>
  <section class="GANDG wow fadeIn">

    <div class="container">
      <div class="row wow animate__fadeIn" data-wow-delay="0.5s">
        <div class="col-sm-12 col-lg-4 porque-wrap" style="padding-left:1em;">
          <h2 class="porque"><b> {{(leng == 'es')?'¿Por qué Guillen & Guillen Abogados?':'¿Why Guillen & Guillen Attorneys at Law?'}}</b></h2>
          <div class="linea"></div>
        </div>
        <div class="col-sm-12 col-lg-8 cuestions-Text" v-if="leng == 'es'">
          <p>
            Somos la firma líder en Honduras. 
            <hr>
            Colaboramos al éxito de tu negocio con nuestras soluciones legales, integrales y estratégicas.
            <hr>
            Contamos con un equipo de trabajo de abogados experimentados y especializados en las diferentes ramas del derecho, que te garantiza la mejor asesoría en el ámbito legal. 
            <hr>
            Nuestra amplia visión del mundo empresarial y de negocios, nos convierte no solo en tus asesores legales sino en tus socios estratégicos brindándote soluciones legales e integrales con resultados apegados a tus necesidades. 
            <hr>
            Innovamos y nos transformamos constantemente para estar a la vanguardia del mundo tecnológico, con el fin de ser eficientes y diligentes con tiempos de entrega. 
          </p>
        </div>
        <div class="col-sm-12 col-lg-8 cuestions-Text" v-if="leng == 'en'">
          <p>
            We are a top leading firm in Honduras. 
            <hr>
            We collaborate to the success of your business with comprehensive
            and strategic legal solutions.
            <hr>
            We have a team of experienced lawyers, specialized in the different
            branches of the law, which guarantees the best advice in the legal
            field.
            <hr>
            Our broad vision of the business and corporate world makes us not
            only your legal advisors, but also your strategic partners,
            providing integral and legal solutions with results tailored to
            your needs.
            <hr>
            We constantly innovate and transform ourselves to be at the
            forefront of the technological world, in order to be efficient and
            diligent with our turnaround time and deadlines.
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="Cuestions">
    <div
      class="container count wow animate__fadeIn"
      data-wow-delay="0.5s"
      v-if="leng == 'es'"
    >
      <div class="count_num">
        <h1 class="numero">1</h1>
        <h2 class="num">País</h2>
      </div>
       <div class="count_num">
        <h1 class="numero">1</h1>
        <h2 class="num">Oficina</h2>
      </div>
      <div class="count_num">
        <h1 class="numero">9</h1>
        <h2 class="num">Abogados y Notarios</h2>
      </div>
         <div class="count_num">
        <h1 class="numero">13</h1>
        <h2 class="num">Sectores</h2>
      </div>
      <div class="count_num">
        <h1 class="numero">16</h1>
        <h2 class="num">Áreas de Práctica</h2>
      </div>
   
     
    </div>
    <div
      class="container count wow animate__fadeIn"
      data-wow-delay="0.5s"
      v-if="leng == 'en'"
    >
      <div class="count_num">
        <h1 class="numero">1</h1>
        <h2 class="num">Country</h2>
      </div>
      <div class="count_num">
        <h1 class="numero">1</h1>
        <h2 class="num">Office</h2>
      </div>
      <div class="count_num">
        <h1 class="numero">9</h1>
        <h2 class="num">Lawyers and Notaries</h2>
      </div>
      <div class="count_num">
        <h1 class="numero">13</h1>
        <h2 class="num">Sectors</h2>
      </div>
      <div class="count_num">
        <h1 class="numero">16</h1>
        <h2 class="num">Practice Areas</h2>
      </div>
    </div>
   
     <div
        class="mail wow animate__fadeIn"
        data-wow-delay="0.5s"
        data-wow-duration="1s"
      >
      <center class="wow animate__fadeIn" data-wow-delay="0.5s">
        <h2 ><b> {{leng == 'es'?'¿Necesitas Asesoría Legal':'Need legal advice?'}} </b></h2>
       
        <div class="linea"></div>
        <br />
      </center>
        <div class=" wow animate__bounceIn"   data-wow-delay="1s"
        data-wow-duration="1s">
       

       

        <a class="correo-link" target="_blank" href="mailto:contact@guillen-law.com">
        <b>contact@guillen-law.com</b>
        </a>
        </div>

   
      </div>
  </section>
</template>

<script>
// @ is an alias to /src
import store from "@/store/index.js";

export default {
  name: "Home",
  components: {},
  data() {
    this.$i18n.locale = store.state.activo;
    return {
      leng: store.state.activo,
    };
  },
  mounted: function () {
    window.scrollTo(0, 0);

    // gsap.to('.phones', {x: 500, duration: 3})
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  watch: {
    cambioState(val) {
      this.$i18n.leng = val;
      this.$i18n.locale = val;
      this.leng = val;
    },
  },
  setup() {
    const beforeEnter = (el) => {
      console.log("beforeEnter");
      el.style.opacity = 0;
      el.style.transform = "translateY(100px)";
    };
    const enter = (el, done) => {
      console.log("enter");
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 1,
        onComplete: done,
        ease: "bounce.out",
        delay: 1,
        //        duration: 3,
        // opacity: 1,
        // scale: 0,
        // y: 80,
        // rotationX: 180,
        // transformOrigin: "0% 50% -50",
        // ease: "bounce.in",
        // stagger: 0.01
      });
    };
    return { beforeEnter, enter };
  },
};
</script>
<style lang="scss">
@import "@/_responsive.scss";
.linea {
  width: 50px;
  height: 10px;
  background-color: #c7893e;
  margin-bottom: 10px;
}
.contact-btn {
  width: 200px;
}
.porque {
  font-size: 2.5em;
  margin-top: 30px;
  text-align: left;
  @include responsive(mobile) {
    font-size: 2em;
  }
}
.porque-wrap {
  @include responsive(mobile) {
    padding-left: 30px !important;
  }
}
.home {
  height: 100vh;
  width: 100%;
  background-image: url("../assets/Tegus-min.jpg");
  background-size: cover;
  background-position-y: -80px;
  background-repeat: no-repeat;
  background-color: #181648;
  border-bottom: #c7893e 10px solid;

  @include responsive(mobile) {
    height: 100vh;
    width: 100%;
    background-position-y: 0px;
    background-position-x: -0px;
    -webkit-animation: kenburns-bottom-right 60s ease-out infinite;
    animation: kenburns-bottom-right 60s ease-out infinite;
  }
  @include responsive(small) {
    height: 50vh;
    width: 100%;
    background-position-y: 0px;
    background-position-x: -0px;
    -webkit-animation: kenburns-bottom-right 60s ease-out infinite;
    animation: kenburns-bottom-right 60s ease-out infinite;
  }
  .texto {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .text {
      width: 50%;
      color: white;
      text-align: left;
      .titulo {
        text-shadow: #0000007c 0px 2px 5px;
      }
      @include responsive(mobile) {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        h2 {
          text-shadow: rgba(112, 112, 112, 0.308) 10px 10px 10px;
        }
      }
    }
  }
}
.GANDG {
  width: 100%;
  height: auto;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  @include responsive(mobile) {
    height: auto;
  }
  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 50px;
    padding-bottom: 100px;

    @include responsive(mobile) {
      flex-direction: column;
      padding: 10px;
    }
    img {
      width: 20%;
      @include responsive(mobile) {
        width: 40%;
        margin-top: 10px !important;
        margin-bottom: 30px;
      }
    }
    .texto-intro {
      text-align: left;
      margin-left: 50px;
      @include responsive(mobile) {
        margin-left: 0px;
        text-align: left;
      }
    }
  }
}

.Cuestions {
  width: 100%;
  height: auto;

  @include responsive(mobile) {
    margin-bottom: 0px;
  }
  .count {
    background-color: #181648;
    height: 150px;
    z-index: 1;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include responsive(mobile) {
      transform: translateY(0%);
      height: 200px;
    }
    .count_num {
      width: 150px;
      height: 100px;
      color: white;
      font-family: "Quicksand", sans-serif;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include responsive(mobile) {
      }
    }
  }
}
.num {
  font-family: "raleway-light" !important;
  font-size: 14px;
  text-transform: capitalize;

  @include responsive(mobile) {
    height: 300px;
    flex-wrap: wrap;
    transform: translateY(50%);
  }
}
.img {
  background-image: url("../assets/catedral-sps.jpg");

  background-size: cover;
  background-position: center;
  @include responsive(mobile) {
    height: 400px;
    margin-bottom: 30px;
  }
}
.cuestions-Text {
  text-align: left;
  padding-left: 30px;
  padding-right: 30px;
}
.values {
  text-align: center;

  margin-top: 50px;
  .row {
    .wrap-value {
      padding: 20px;
      text-align: justify;
    }
  }
}
.mail {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  // background-image: url("../assets/Footers.jpg");
  background-size: cover;
  // background-attachment: fixed;
  background-position: center;
  background-color: #f4f4f4;
  image-resolution: 0;
  flex-direction: column;

  div {
    .correo-link {
      text-decoration: none;
      color: #181648;
      transition: 0.3s ease-in-out;
      font-size: 2em;
      @include responsive(mobile) {
        font-size: 25px !important;
      }

      &:hover {
        color: #c7893e;
      }
    }
  }
}
.numero {
  color: #c7893e !important;
}
// @-webkit-keyframes kenburns-bottom-right {
//   0% {
//     -webkit-transform: scale(1) translate(0, 0);
//             transform: scale(1) translate(0, 0);
//     -webkit-transform-origin: 84% 84%;
//             transform-origin: 84% 84%;
//   }
//   100% {
//     -webkit-transform: scale(1.25) translate(20px, 15px);
//             transform: scale(1.25) translate(20px, 15px);
//     -webkit-transform-origin: right bottom;
//             transform-origin: right bottom;
//   }
// }
@keyframes kenburns-bottom-right {
  0% {
    background-position-x: right;
  }
  50% {
    background-position-x: left;
  }
  100% {
    background-position-x: right;
  }
}
</style>