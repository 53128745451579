
<i18n>
{
  "en": {
    "inicio": "Home",
    "Sobre": "Our firm",
    "Services": "Our services",
    "team": "Our team",
    "Contacto": "Contact",
        "Noso": "About us",
          "valores":"Values"
    
 
  },
  "es": {
      "inicio": "Inicio",
    "Sobre": "Nuestra Firma",
    "Services": "Nuestros Servicios",
    "Contacto": "Contacto",
    "team": "Nuestro Equipo",
    "Noso": "Sobre Nosotros",
   "valores":"Valores"
  }
}
</i18n>


<template>
  <div class="btn menu-btn" @click="Open">
    <i :class="menu ? 'fas fa-times ' : 'fas fa-bars'"></i>
  </div>
  <transition name="fade">
    <div class="wrap-list-mobile" v-if="menu">
      <div class="links-mobile">
        <router-link @click="Open" class="lnk" to="/">{{
          $t("inicio")
        }}</router-link>
        <router-link @click="Open" class="lnk" to="/about">{{
          $t("Sobre")
        }}</router-link>
        <div class="nav-item dropdown">
          <a
            class="dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("Services") }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <router-link
                class="lnk dropdown-item"
                to="/services"
                @click="Open"
                > {{lengua == 'es'?'Áreas de práctica':'Practice areas'}} </router-link
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <router-link
                class="lnk dropdown-item"
                to="/sectores"
                @click="Open"
                >{{lengua == 'es'?'Sectores':'Sectors'}}</router-link
              >
            </li>
          </ul>
        </div>
        <router-link @click="Open" class="lnk" to="/contact">{{
          $t("Contacto")
        }}</router-link>
        <div class="leng lnk">
          <button
            :class="lengua == 'es' ? 'active' : ''"
            @click="lengua = 'es'"
          >
            ESP
          </button>
          <button
            :class="lengua == 'en' ? 'active' : ''"
            @click="lengua = 'en'"
          >
            ENG
          </button>
        </div>
      </div>
    </div>
  </transition>
  <div class="navbar">
    <div class="container wrap-menu">
      <router-link style="border-bottom: none" to="/">
        <img src="@/assets/logo.svg" v-if="lengua == 'es'" class="logo" />
        <img src="@/assets/logo-en.svg" v-if="lengua == 'en'" class="logo" />
        <!-- <img src="@/assets/logo-blanco.svg" class="logo" > -->
      </router-link>
      <!-- <Hello /> -->

      <div class="links">
        <router-link class="lnk" to="/">{{ $t("inicio") }}</router-link>
        <router-link class="lnk" to="/about">{{ $t("Noso") }}</router-link>
        <div class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{ $t("Services") }}
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li>
              <router-link class="lnk dropdown-item" to="/services"
                >{{lengua == 'es'?'Áreas de práctica':'Practice areas'}}</router-link
              >
            </li>
            <li><hr class="dropdown-divider" /></li>
            <li>
              <router-link class="lnk dropdown-item" to="/sectores"
                >{{lengua == 'es'?'Sectores':'Sectors'}}</router-link
              >
            </li>
          </ul>
        </div>

        <!-- <router-link  class="lnk" to="/services">{{ $t("Services") }}</router-link> -->

        <router-link class="lnk" to="/Team">{{$t('team')}}</router-link>
        <router-link class="lnk" to="/contact">{{
          $t("Contacto")
        }}</router-link>

        <div class="leng lnk">
          <button
            :class="lengua == 'es' ? 'active' : ''"
            @click="lengua = 'es'"
          >
            ESP
          </button>
          <button
            :class="lengua == 'en' ? 'active' : ''"
            @click="lengua = 'en'"
          >
            ENG
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store/index.js";
export default {
  name: "Nav",
  props: {
    msg: String,
  },
  components: {},
  data() {
    this.$i18n.locale = store.state.activo;
    return {
      lengua: store.state.activo,
      bgColor: "#181547",
      menu: false,
    };
  },
  watch: {
    lengua(val) {
      store.commit("ActualizarStado", val);
      this.$i18n.locale = val;
      console.log(this.$i18n.locale);
    },
  },
  computed: {
    cambioState() {
      return store.getters.stadocambio;
    },
  },
  methods: {
    Open: function () {
      this.menu = !this.menu;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/_responsive.scss";

.dropdown-menu {
  position: absolute;
  z-index: 1000;

  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;

  color: #b47931 !important;
  text-align: left;
  list-style: none;
  background-color: #c7893e;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #181547 !important;
  background-color: #e9ecef59;
}
html[data-scroll="0"] {
  .navbar {
    // background-color: transparent !important;
    position: absolute;
    width: 100%;
    height: 120px;
    z-index: 50;

    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;

    a {
      font-weight: bold;
      margin-right: 20px;
      color: #ffffff;
      transition: 0.3s ease-in-out;
      text-transform: uppercase;
      &.router-link-exact-active {
        color: #ffffff !important;

        border-bottom: 3px #c7893e solid;
      }
    }
    @include responsive(mobile) {
      position: absolute;
      width: 100%;
      height: 70px;
      z-index: 50;
      background-color: #181547;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      transition: 0.3s ease-in-out;
      font-family: "raleway-light";

      a {
        font-weight: bold;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        &.router-link-exact-active {
        }
      }
    }
    @include responsive(small) {
      position: absolute;
      width: 100%;
      height: 70px;
      z-index: 50;
      background-color: #181547;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      transition: 0.3s ease-in-out;
      font-family: "raleway-light";

      a {
        font-weight: bold;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        &.router-link-exact-active {
        }
      }
    }
  }
  .logo {
    width: 15rem;
    z-index: 200;
    @include responsive(mobile) {
      width: 9rem;
    }
    @include responsive(small) {
      width: 9rem;
    }
  }
}
.wrap-list-mobile {
  width: 100%;
  height: 100vh;
  background-color: #181547;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  position: absolute;
  position: fixed;
  .links-mobile {
    width: 80%;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;

    .lnk {
      margin-right: 5px;
      margin-left: 5px;
      text-decoration: none;
      font-size: 15px !important;
      text-transform: uppercase;
    }
    .leng {
      button {
        border: white 1px solid;
        color: white;
        background-color: transparent;
        margin-left: 10px;
      }
      .active {
        border: #c7893e 1px solid;
        color: white !important;
        background-color: #c7893e;
      }
    }
  }
}

.menu-btn {
  @include responsive(mobile) {
    display: block;
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: 1px #c7893e solid;
    display: flex;
    position: absolute;
    z-index: 900;
    right: 20px;
    position: fixed;
    top: 20px;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  @include responsive(small) {
    display: block;
    height: 30px;
    width: 30px;
    background-color: transparent;
    border: 1px #c7893e solid;
    display: flex;
    position: absolute;
    z-index: 900;
    right: 20px;
    position: fixed;
    top: 20px;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  height: 30px;
  width: 30px;
  background-color: transparent;
  border: 1px #c7893e solid;
  display: flex;
  position: absolute;
  z-index: 1000;
  right: 20px;
  position: fixed;
  top: 20px;
  justify-content: center;
  align-items: center;
  color: #ffffff;

  display: none;
}

.navbar {
  // background-color: transparent !important;
  position: absolute;
  width: 100%;
  height: 70px;
  z-index: 50;
  z-index: 100;
  background-color: #181547;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  transition: 0.3s ease-in-out;
  font-family: "raleway-light";
  font-size: 15px !important;
  .wrap-menu {
    justify-content: space-between;
  }

  a {
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin-right: 20px !important;
    text-transform: uppercase;
    font-size: 0.8em;
    &.router-link-exact-active {
      color: #ffffff !important;
    }
  }
}
.logo {
  width: 12rem;
  z-index: 200;
  transition: 0.3s ease-in-out;
  @include responsive(mobile) {
    width: 9rem;
  }
  @include responsive(small) {
    width: 9rem;
  }
}
a {
  font-weight: bold;
  color: white;
  text-decoration: none;
  margin-right: 20px;
  text-transform: uppercase;
  &.router-link-exact-active {
    color: #ffffff !important;
  }
}
.links {
  width: auto;
  height: 60px;
  display: flex !important;
  flex-wrap: nowrap;
  justify-content: flex-end;
  // background-color: #c7893e;
  align-items: center;
  @include responsive(desktop) {
    display: block;
  }
  @include responsive(mobile) {
    display: none !important;
  }
  @include responsive(small) {
    display: none !important;
  }
  .lnk {
    margin-right: 5px;
    margin-left: 5px;
  }
  .leng {
    width: 120px;
    button {
      border: white 1px solid;
      color: white;
      background-color: transparent;
      margin-left: 10px;
    }
    .active {
      border: #c7893e 1px solid;
      color: white;
      background-color: #c7893e;
    }
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 1;
}
</style>
