import { createStore } from 'vuex'

export default createStore({
  state: {
    activo:'es'
    },
    mutations: {
      ActualizarStado(state, len) {
        state.activo = len;
        // console.log('State =', state.activo)
        console.log('estado es:'+state.activo)
        return state.activo;
      
      }
    },
    actions: {
      cambiAct(state) {
        state.commit('cambiAct')
  
      }
    },
    getters: {
      stadocambio: state => {
          return state.activo
      }
    },
   
  modules: {
  }
})
