
import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import i18n from './i18n'
import './main.scss'
import wow from 'wowjs'
import './animate.css'
import VueSweetalert2 from 'vue-sweetalert2';
const options = {
    confirmButtonColor: '#d8272d',
    cancelButtonColor: 'rgb(176, 176, 176)',
  };


  


createApp(App).use(i18n).use(wow).use(store).use(VueSweetalert2, options).use(router).mount('#app')
