<template>
  <Preloader />
  <!-- <Cursor/> -->
  <div id="app">
    <Nav />

    <router-view :key="$router.path" />

    <Footer />
  </div>
</template>
<script>
import Preloader from "./components/preloader.vue";
import Nav from "./components/nav.vue";
import Footer from "./components/Footer.vue";
import Cursor from "./components/cursor.vue";

export default {
  name: "app",
  props: {
    msg: String,
  },
  components: {
    Nav,
    Footer,
    Cursor,

    Preloader,
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  position: relative;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
